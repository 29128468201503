import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`Szia! Németh Feri vagyok, szoftverfejlesztő mérnök a gombáztúrák megszállotja.`}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`Hosszú évek óta ülőmunkát végzek, melyet muszáj kompenzálni. Sok mozgásformával próbálkoztam, a legértékesebbenk a természetben eltóltótt aktv idő tűnt. Mivel az első gombásztúra élményem azonnal beszippantott, elkezdtem tudatosan időt szakítani új szenvedélyemnek. A kora reggeli kelések sem tántorítottak el. Épp ellenkezőleg, a gombászás célt adott az ébredésnek és a természetjárás újra fontos részévé vált az életemnek.`}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`A kosár nálam állandó készenlétben áll, gombahatározó könyvek sorakoznak a polcomon és egy biológai mikroszkóp is beköltözött a lakásba.`}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`2020-ban gombaszakellenőri vizsgát tettem le. 2021-ben bronzjelvényes túravezetői lettem és a `}<a parentName="p" {...{
        "href": "http://gombanet.hu"
      }}>{`Mikológiai Társaság`}</a>{` felsőfokú gombaismereti tanfolyamával a mikológiai tanulmányaim újabb szakasza kezdődött meg. Ennek a hosszú távú tanulási és alkotási folyamatnak ad keretet a Kinoko project.`}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`Ezen a honlapon olvashatod a gombásztúra élményemit. Az számomra érdekes fajleírások mellett könnyed hétvégi főzőcskék és komolyabb fermentálási kísérletek eredményét is megírom.`}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`Egy-egy témával kapcsolatban több hazai és határon túli szakértőt keresek meg. Kutatókkal, toplászokkal, triflászokkal és szakácsokkal is beszélgetek, amit itt, a Kinoko blogon osztok meg Veletek.`}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`Gyere és tanuljunk együtt a gombák lenyűgöző világáról! `}</p>
    <p {...{
      "className": "max-w-2xl mx-auto"
    }}>{`Kövess a következő közösségi platformokon: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/Kinoko-Project-113837197040339"
      }}>{`Facebook`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.instagram.com/kinoko.project/"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      